import { biliBridge } from '@bilibili/js-bridge'
import { isAndroid } from '../canvas/utils'
import { getQuery } from '@/utils'

export default {
  data() {
    return {
      isMobile:
        /view=h5/.test(window.location.href) || /Android|iPhone|iPod/i.test(navigator.userAgent),
      model: '',
      build: 0
    }
  },
  computed: {
    isIpadHD() {
      return this.model === 'ipad'
    },
    isPc() {
      return !this.isMobile && !this.isIpadHD
    },
    appStyle() {
      let isFrame = getQuery('is_gaoneng_iframe')
      let zoom = 1
      if (this.isMobile) {
        zoom = document.documentElement.clientWidth / 375
      }
      if (this.isPc) {
        zoom = 2
      }
      if (this.isIpadHD) {
        zoom = 517 / 375
      }
      if (isFrame) {
        zoom = isFrame / 375
      }

      return {
        zoom
      }
    }
  },
  methods: {
    getModel(callback) {
      if (window.alita) {
        window.alita.getNativeInfo().then((res) => {
          this.model = res.model
          this.build = res.build_id

          try {
            // 注入商业namespace
            biliBridge.callNative({
              method: 'global.import',
              data: {
                namespace: 'cm'
              },
              callback: () => {
                callback && callback()
              }
            })
            // 安卓拿不到注册的回调
            if (isAndroid) {
              this.$nextTick(() => {
                callback && callback()
              })
            }
          } catch (e) {
            callback && callback()
          }
        })
      }
    }
  },
  created() {
    this.getModel()
  }
}
