/* eslint-disable */
// https://info.bilibili.co/pages/viewpage.action?pageId=213480007
export const MINI_page_show = 'MINI_page_show'
export const MINI_page_load_success = 'MINI_page_load_success'
export const MINI_page_rendered = 'MINI_page_rendered'
export const MINI_page_load_fail = 'MINI_page_load_fail'
export const MINI_page_close = 'MINI_page_close'
export const MINI_item_click = 'MINI_item_click'
export const MINI_item_cvr_click = 'MINI_item_cvr_click'
export const MINI_list_input = 'MINI_list_input'
export const MINI_list_idcard = 'MINI_list_idcard'
export const MINI_list_checkbox = 'MINI_list_checkbox'
export const MINI_list_radio = 'MINI_list_radio'
export const MINI_list_address = 'MINI_list_address'
export const MINI_list_phone = 'MINI_list_phone'
export const MINI_list_dropdown = 'MINI_list_dropdown'

export const MINI_list_input_cvr = 'MINI_list_input_cvr'
export const MINI_list_idcard_cvr = 'MINI_list_idcard_cvr'
export const MINI_list_checkbox_cvr = 'MINI_list_checkbox_cvr'
export const MINI_list_radio_cvr = 'MINI_list_radio_cvr'
export const MINI_list_address_cvr = 'MINI_list_address_cvr'
export const MINI_list_phone_cvr = 'MINI_list_phone_cvr'
export const MINI_list_dropdown_cvr = 'MINI_list_dropdown_cvr'
export const MINI_lbs_dropdown_click_cvr = 'MINI_lbs_dropdown_click_cvr'

export const MINI_video_startplay = 'MINI_video_startplay'
export const MINI_video_playduration = 'MINI_video_playduration'
export const MINI_list_success = 'MINI_list_success'
export const MINI_list_fail = 'MINI_list_fail'
export const MINI_list_form_submit_click = 'MINI_list_form_submit_click'
export const MINI_page_click = 'MINI_page_click'
export const MINI_video_playcount = 'MINI_video_playcount'
export const MINI_href_success = 'MINI_href_success'
export const MINI_href_fail = 'MINI_href_fail'
export const MINI_list_pv = 'MINI_list_pv'
export const MINI_scheme_stop = 'MINI_scheme_stop'
export const MINI_show_free_flow = 'MINI_show_free_flow'
export const MINI_hide_free_flow = 'MINI_hide_free_flow'
export const MINI_need_free_click = 'MINI_need_free_click'
export const MINI_no_free_click = 'MINI_no_free_click'
export const MINI_page_callup_app_stay_time = 'MINI_page_callup_app_stay_time'
export const MINI_page_callup_suc = 'MINI_page_callup_suc'
export const MINI_page_callup_fail = 'MINI_page_callup_fail'
export const MINI_form_fixed_click = 'MINI_form_fixed_click'

export const H5_enter = 'H5_enter'
export const MINI_item_img_download_click = 'MINI_item_img_download_click'
export const MINI_item_img_download_error = 'MINI_item_img_download_error'
export const MINI_get_form_history = 'MINI_get_form_history'
export const MINI_no_get_form_history = 'MINI_no_get_form_history'

export const MINI_page_slide = 'MINI_page_slide'
export const MINI_page_slide_cvr = 'MINI_page_slide_cvr'
export const MINI_form_loading_success = 'MINI_form_loading_success'
export const MINI_video_replay_click = 'MINI_video_replay_click'
export const MINI_item_show = 'MINI_item_show'
export const MINI_video_show = 'MINI_video_show'
export const MINI_item_img_load_finish = 'MINI_item_img_load_finish'
export const MINI_video_fullscreen_click = 'MINI_video_fullscreen_click'
export const MINI_video_unfullscreen_click = 'MINI_video_unfullscreen_click'
export const MINI_video_play_click = 'MINI_video_play_click'
export const MINI_video_loading_success = 'MINI_video_loading_success'

export const MINI_download_modal_show = 'MINI_download_modal_show'
export const MINI_download_modal_success = 'MINI_download_modal_success'
export const MINI_download_modal_fail = 'MINI_download_modal_fail'
export const MINI_auto_download_show = 'MINI_auto_download_show'
export const MINI_auto_download_click = 'MINI_auto_download_click'

export const MINI_click_add_form_button = 'MINI_click_add_form_button'
export const MINI_experiment_add_form_button = 'MINI_experiment_add_form_button'
export const MINI_use_local_phone_suc = 'MINI_use_local_phone_suc'
export const MINI_use_local_phone_fail = 'MINI_use_local_phone_fail'
export const MINI_local_phone_token_suc = 'MINI_local_phone_token_suc'
export const MINI_local_phone_token_fail = 'MINI_local_phone_token_fail'
export const MINI_click_use_local_phone = 'MINI_click_use_local_phone'
export const MINI_click_use_sdk_token = 'MINI_click_use_sdk_token'
export const MINI_click_use_local_token = 'MINI_click_use_local_token'
export const MINI_local_phone_recode_token_suc = 'MINI_local_phone_recode_token_suc'
export const MINI_local_phone_recode_token_fail = 'MINI_local_phone_recode_token_fail'
export const MINI_submit_phone_equal = 'MINI_submit_phone_equal'
export const MINI_close_float_form = 'MINI_close_float_form'
export const MINI_callup_weixin_game_success = 'MINI_callup_weixin_game_success'
export const MINI_callup_weixin_game_fail = 'MINI_callup_weixin_game_fail'
export const MINI_callup_weixin_not_install = 'MINI_callup_weixin_not_install'
export const MINI_copy_wx_success = 'MINI_copy_wx_success'
export const MINI_copy_wx_fail = 'MINI_copy_wx_fail'
export const MINI_copy_wx_modal_close = 'MINI_copy_wx_modal_close'
export const MINI_copy_wx_modal_show = 'MINI_copy_wx_modal_show'
export const MINI_copy_wx_modal_click = 'MINI_copy_wx_modal_click'

export const MINI_lbs_dropdown_click = 'MINI_lbs_dropdown_click'
export const MINI_lbs_model_show = 'MINI_lbs_model_show'
export const MINI_lbs_model_yes = 'MINI_lbs_model_yes'
export const MINI_lbs_model_no = 'MINI_lbs_model_no'
export const MINI_lbs_get_location_success = 'MINI_lbs_get_location_success'
export const MINI_lbs_get_location_fail = 'MINI_lbs_get_location_fail'
export const MINI_lbs_city_code_not_found = 'MINI_lbs_city_code_not_found'
export const MINI_lbs_city_code_not_found_ip = 'MINI_lbs_city_code_not_found_ip'
export const MINI_lbs_city_code_not_match = 'MINI_lbs_city_code_not_match'

export const MINI_jsb_request = 'MINI_jsb_request'
export const MINI_jsb_not_support = 'MINI_jsb_not_support'
export const MINI_jsb_callback = 'MINI_jsb_callback'
export const MINI_jsb_error = 'MINI_jsb_error'

export const MINI_tel_code_success = 'MINI_tel_code_success'
export const MINI_tel_code_fail = 'MINI_tel_code_fail'
export const MINI_tel_code_verification_success = 'MINI_tel_code_verification_success'
export const MINI_tel_code_verification_fail = 'MINI_tel_code_verification_fail'
export const MINI_login_phone_modal_show = 'MINI_login_phone_modal_show'
export const MINI_login_phone_modal_yes = 'MINI_login_phone_modal_yes'
export const MINI_login_phone_modal_no = 'MINI_login_phone_modal_no'
export const MINI_login_phone_modal_overlay = 'MINI_login_phone_modal_overlay'
export const MINI_login_phone_success = 'MINI_login_phone_success'
export const MINI_login_phone_fail = 'MINI_login_phone_fail'
export const MINI_web_cvr_up = 'MINI_web_cvr_up'
export const MINI_assistant_click = 'MINI_assistant_click'
export const MINI_work_wechat_id_fail = 'MINI_work_wechat_id_fail'

export const MINI_ios_app_preload_success = 'MINI_ios_app_preload_success'
export const MINI_ios_app_preload_error = 'MINI_ios_app_preload_error'

export const MINI_game_app_download_error = 'MINI_game_app_download_error'
export const MINI_game_app_download_success = 'MINI_game_app_download_success'
export const MINI_operate_button_page_show = 'MINI_operate_button_page_show'
export const MINI_operate_no_page_show = 'MINI_operate_no_page_show'
export const MINI_operate_page_show = 'MINI_operate_page_show'
export const MINI_operate_button_page_click = 'MINI_operate_button_page_click'
export const MINI_operate_api_success = 'MINI_operate_api_success'
export const MINI_operate_api_error = 'MINI_operate_api_error'

// heatmap 热力图相关
export const MINI_heatmap_click = 'MINI_heatmap_click'
